import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
    Nav,
    Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { book, bookurl, messages } from './../util/book.js'

import Layout from '../components/layout'
import LocalImage from '../components/local-image';

import './../css/home.css'

class IndexPage extends Component {
    render() {

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    // console.log(i18n)
                    const lang = i18n.language;
                    const path = "/"+lang
                    // console.log( 'language : ' + lang)

                    return (
                        <>
                        <Layout>
                            <div id="bodyPage" className="body-page body-bg">
                                <main role="main" className="main">
                                <Container>
                                    <div className="bookInfo">
                                        <Row>
                                            <Col xs={5} md={3}>
                                                <LocalImage name="arw_cover" attr={{className: "cover w-100", alt:`Cover`}}/>
                                            </Col>

                                            <Col xs={7} md={9} className="pl-0">
                                            <h3 className="title">{book.bookTitle[lang]}</h3>
                                            {messages[lang].labelCreator}    : {messages[lang].txtCreator}<br/>
                                            {messages[lang].labelAuthor}     : {messages[lang].txtAuthor}<br/>

                                            {{ 'en': 'Translator : Johnny Ko, Harriet Chung, Gigi Chang and Anna Holmwood', 'zh-hk': '', 'zh-cn': '' }[lang]}


                                                <div className="description d-none d-md-block">
                                                    <p>{messages[lang].txtDescription}</p>
                                                    <p className="text-center">
                                                    <a href={ path + bookurl["0.0"]} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                    </p>
                                                </div>

                                            </Col>

                                            <Col xs={12} className="description d-block d-md-none">
                                                <p>{messages[lang].txtDescription}</p>
                                                <p className="text-center">
                                                <a href={ path + bookurl["0.0"]} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="bookIndex">
                                        <Row className="chapter-block">
                                            <Col className="title-hl">
                                                <Nav.Link href={ path + bookurl["0.0"]}>{book["0.0"][lang]}</Nav.Link>
                                            </Col>
                                        </Row>

                                        <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 1', 'zh-hk': '第1章', 'zh-cn': '第1章' }[lang]}
                                                <div>{book["1"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["1.1"]} className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 1.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["1.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["1.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 1.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["1.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["1.3"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 1.3 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["1.3"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row>

                                        <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 2', 'zh-hk': '第2章', 'zh-cn': '第2章' }[lang]}
                                                <div>{book["2"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["2.1"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 2.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["2.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["2.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 2.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["2.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["2.3"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 2.3 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["2.3"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row>

                                        <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 3', 'zh-hk': '第3章', 'zh-cn': '第3章' }[lang]}
                                                <div>{book["3"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["3.1"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 3.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["3.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["3.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 3.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["3.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["3.3"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 3.3 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["3.3"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row>
                                        <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 4', 'zh-hk': '第4章', 'zh-cn': '第4章' }[lang]}
                                                <div>{book["4"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["4.1"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 4.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["4.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["4.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 4.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["4.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["4.3"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 4.3 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["4.3"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row>
                                        <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 5', 'zh-hk': '第5章', 'zh-cn': '第5章' }[lang]}
                                                <div>{book["5"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["5.1"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 5.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["5.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["5.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 5.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["5.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row>

                                    </div>

                                </Container>

                                </main>
                            </div>
                        </Layout>
                        </>
                    )
                }}

            </NamespacesConsumer>
        )
    }
}

export default withI18next()(IndexPage)

export const query = graphql`
    query getHomeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
